/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export function PantryManagementPage() {
    return (
        <>
            <div className='d-flex flex-wrap flex-stack mb-6'>
                <h3 className='fw-bolder my-2'>
                    Pantry Management
                </h3>
            </div>
        </>
    )
}
