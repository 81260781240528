/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import { useNavigate } from 'react-router-dom'

const DashboardPage: FC<{history:any}> = ({ history }) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-12 text-center'>
        <h2>Welcome to Smart digibuild</h2>
        <p className='mb-2 mt-2'>Click on the below button to add User</p>
        <button
          type='submit'
          className='btn btn-primary mt-6'
          data-kt-users-modal-action='submit' onClick={(e)=>{
            history('/apps/user-management/users')
          }}
        >
          Add User
        </button>
        <div className='mt-8'> <img src="media/dashboard.png" /></div>
      </div>
    </div>
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const history = useNavigate();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage history={history} />
    </>
  )
}

export { DashboardWrapper }
