/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { currentUser, logout } = useAuth()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
        iconType='svg'
      />
      {currentUser?.role === 'sa' &&
        <SidebarMenuItem
          to='/dashboard'
          icon='element-11'
          title='Customer'
          fontIcon='bi-app-indicator'
          iconType='svg'
        />
      }
      {currentUser?.role === 'admin' &&
        <SidebarMenuItemWithSub to='/pod' title='POD' iconType='font' fontIcon='fa-solid fa-sitemap'>
          <SidebarMenuItem to='/pod/location' title='Location' hasBullet={true} />
          <SidebarMenuItem to='/pod/building' title='Building' hasBullet={true} />
          <SidebarMenuItem to='/pod/floor' title='Floor' hasBullet={true} />
          <SidebarMenuItem to='/pod/zone' title='Zone' hasBullet={true} />
        </SidebarMenuItemWithSub>
      }
      <SidebarMenuItem
        to='/modules'
        iconType='font'
        title={'Modules'}
        fontIcon='fa-solid fa-table-cells-large'
      />
      {(currentUser?.role === 'admin' || currentUser?.role === 'sa') && <React.Fragment>
        <SidebarMenuItem
          to='/apps/user-management/users'
          title={'Users'}
          iconType='font'
          fontIcon='fa fa-users'
        />
        <SidebarMenuItemWithSub to='/settings' title='Setting' iconType='font'
          fontIcon='fa-solid fa-gear'>
          <SidebarMenuItem to='/settings/logs' title='Log' hasBullet={true} />
          <SidebarMenuItem to='/settings/personalization' title='Personalization' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem
          to='/reports'
          icon='element-11'
          title={'Reports'}
          fontIcon='fa-solid fa-chart-simple'
          iconType='font'
        />
      </React.Fragment>
      }


    </>
  )
}

export { SidebarMenuMain }
