/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useNavigate } from 'react-router-dom';

export function ModuelsPage() {
    const history = useNavigate();
    return (
        <>
            <div className='d-flex flex-wrap flex-stack mb-6'>
                <h3 className='fw-bolder my-2'>
                    Modules
                </h3>
            </div>
            <div className='row modules'>
                <div className='col-4 c-p' onClick={()=>{
                     history('/modules/visitor-management');
                }}>
                    <img src="media/Group 26086273.png" className="card-img-top " alt="..." />
                </div>
                {/* <div className='col-4 c-p' onClick={()=>{
                     history('/modules/parking-management');
                }}>
                    <img src="media/Group 26086277.png" className="card-img-top" alt="..." />
                </div>
                <div className='col-4 c-p' onClick={()=>{
                     history('/modules/pantry-management');
                }}>
                    <img src="media/Group 26086275.png" className="card-img-top" alt="..." />
                </div> */}
            </div>
        </>
    )
}
