import { useEffect, useState } from "react";
import { getLocationByCustomer } from "../pod/components/requests";

export function VisitorInfo(props) {
    console.log(props.visitorInfo)
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [hostInfo, setHostInfo] = useState(null);
    const getLocations = async (customerId: number) => {
        try {
            const { data } = await getLocationByCustomer(customerId)
            if (data) {
                let selectedItems = data.filter(x => props.visitorInfo.visitor_profile.location_id.includes(x._id))
                setSelectedLocation(selectedItems)
            }
        } catch (error) {
            console.error(error)
        }
    }
    /* const getHosterInfo = async (hosterId: number) => {
        try {
            const { data } = await getLocationByCustomer(hosterId)
            if (data) {
                setLocationList(data)
            }
        } catch (error) {
            console.error(error)
        }
    } */
    useEffect(() => {
        getLocations(props?.visitorInfo?.visitor_profile?.customer_id)
        //getHosterInfo(props?.visitorInfo?.visit_data?.host_id)
    }, [])
    return (<div className="">
        <div className="row mb-3">
            <label className="col-sm-4 fw-bold col-form-label">Status</label>
            <div className="col-sm-8">
                <div className={`badge fw-bolder ${props?.visitorInfo.visit_data.status === 'pending' ? ' badge-light-warning' : ''} ${props?.visitorInfo.visit_data.status === 'approved' ? 'badge-light-success' : ''} ${props?.visitorInfo.visit_data.status === 'rejected' ? 'badge-light-danger' : ''}`}>
                    {props?.visitorInfo.visit_data.status === 'pending' ? 'Pending' : (props?.visitorInfo.visit_data.status === 'approved' ? 'Approved' : props?.visitorInfo.visit_data.status)}
                </div>
            </div>
        </div>
        <div className="row mb-3">
            <label className="col-sm-4 fw-bold col-form-label">VISITER ID</label>
            <div className="col-sm-8">
                <label className="col-form-label">{props.visitorInfo.visit_data.visitor_id}</label>
            </div>
        </div>
        <div className="row mb-3">
            <label className="col-sm-4 fw-bold col-form-label">USERNAME</label>
            <div className="col-sm-8">
                <label className="col-form-label">{props.visitorInfo.visitor_profile.full_name}</label>
            </div>
        </div>
        <div className="row mb-3">
            <label className="col-sm-4 fw-bold col-form-label">MAIL ID</label>
            <div className="col-sm-8">
                <label className="col-form-label">{props.visitorInfo.visitor_profile.email}</label>
            </div>
        </div>
        <div className="row mb-3">
            <label className="col-sm-4 fw-bold col-form-label">MOBILE</label>
            <div className="col-sm-8">
                <label className="col-form-label">{props.visitorInfo.visitor_profile.phone_number}</label>
            </div>
        </div>
        <div className="row mb-3">
            <label className="col-sm-4 fw-bold col-form-label">HOST NAME</label>
            <div className="col-sm-8">
                <label className="col-form-label">{props.visitorInfo.visit_data.host_id}</label>
            </div>
        </div>

        <div className="row mb-3">
            <label className="col-sm-4 fw-bold col-form-label">VISITING DATE</label>
            <div className="col-sm-8">
                <label className="col-form-label">{props.visitorInfo.visit_data.visiting_date_time ?
                    new Date(props.visitorInfo.visit_data.visiting_date_time).toLocaleDateString() : ''}</label>
            </div>
        </div>
        <div className="row mb-3">
            <label className="col-sm-4 fw-bold col-form-label text-uppercase">duration</label>
            <div className="col-sm-8">
                <label className="col-form-label">{props.visitorInfo.visit_data.duration}</label>
            </div>
        </div>
        <div className="row mb-3">
            <label className="col-sm-4 fw-bold col-form-label text-uppercase">purpose of visit</label>
            <div className="col-sm-8">
                <label className="col-form-label">{props.visitorInfo.visit_data.purpose_of_visit}</label>
            </div>
        </div>
        <div className="row mb-3">
            <label className="col-sm-4 fw-bold col-form-label">Locations</label>
            <div className="col-sm-8">
                <label className="col-form-label">{selectedLocation.map((item: any, i) => {
                    return (<span key={i}>{`${item.name} ${(selectedLocation.length - 1) === i ? '' : ', '}`}</span>)
                })}</label>
            </div>
        </div>
    </div>)
}