import axios from "axios";
import { AuthModel, UserModel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

//export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/me/`;
export const LOGIN_URL = `${API_URL}/auth/login/`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;

// Server should return AuthModel
export function login(email: string, password: string) {
  const params = {
    username: email,
    password,
    grant_type: "password",
    client_id: "",
    client_secret: "",
    scope: "",
  };
  return axios.post<AuthModel>(LOGIN_URL, params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      //Authorization: `Bearer ${token}`, // Include the authorization token in the header
    },
  });
}
