/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field, useFormik, FormikProvider } from 'formik';
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { KTIcon } from '../../../_metronic/helpers';
import { UsersListPagination } from './user-management/users-list/components/pagination/UsersListPagination';
import { useListView } from './user-management/users-list/core/ListViewProvider';
import { addSetupDataByCustomer, approveReject, getSetupDataByCustomer, getVisitorData, getVisitorDataByHost, getVisitorDataByLocation, updateSetupDataByCustomer } from './request';
import { useAuth } from '../auth';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import { VisitorInfo } from './view-visitor-info';


const editUserSchema = Yup.object().shape({
    duration: Yup.string()
        .required('Duration is required'),
    identification_type: Yup.string()
        .required('Identification type is required'),
})

export function VisitorManagementPage() {
    const { currentUser, logout } = useAuth()
    const { isAllSelected, onSelectAll } = useListView()
    const { selected, onSelect } = useListView()
    const isSelected = false
    const [showModal, setModalView] = useState(false);
    const [setupData, setSetupData] = useState(null);
    const [activeView, setActiveView] = useState('setup');
    const [searchText, setSearchText] = useState('')
    const [visitorDataList, setVisitorDataList] = useState([]);
    const [viewModal, setModalData] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const location = useLocation()
    const initialValues = {
        customer_id: '',
        location_id: '',
        duration: '',
        identification_type: ''
    };
    const formik: any = useFormik({
        initialValues,
        validationSchema: editUserSchema,
        onSubmit: (values: any) => {
            if (values._id) {
                updateSetupDataByCustomer(values, values._id).then((daa: any) => {
                    Swal.fire(
                        'Data setup saved!',
                        '',
                        'success'
                    )
                }).catch((error: any) => {
                    Swal.fire(
                        'Data setup failed!',
                        error.response.data.detail,
                        'error'
                    )
                })
            }
            else {
                addSetupDataByCustomer(values).then((daa: any) => {
                    Swal.fire(
                        'Data setup saved!',
                        '',
                        'success'
                    )
                }).catch((error: any) => {
                    Swal.fire(
                        'Data setup failed!',
                        error.response.data.detail,
                        'error'
                    )
                })
            }
        },
    });
    const getSetupData = async (customer_id: string) => {
        try {
            const { data } = await getSetupDataByCustomer(customer_id)
            if (data) {
                formik.setValues(data)
                setSetupData(data)
            }
        } catch (error) {
            console.error(error)
        }
    }
    const getVisitorDataByLocationInfo = async (locationId: string) => {
        try {
            const { data } = await getVisitorDataByLocation(locationId, 0, 20)
            if (data) {
                setVisitorDataList(data)
            }
        } catch (error) {
            console.error(error)
        }
    }
    const getVisitorDatas = async (customerId: string) => {
        try {
            const { data } = await getVisitorData(customerId, 0, 20)
            if (data) {
                setVisitorDataList(data)
            }
        } catch (error) {
            console.error(error)
        }
    }
    const getVisitorInfoByHost = async () => {
        try {
            const { data } = await getVisitorDataByHost(0, 20)
            if (data) {
                setVisitorDataList(data)
            }
        } catch (error) {
            console.error(error)
        }
    }
    
    const approveRejectUser = async (item: any, flag: boolean) => {
        const updateURL = currentUser.role === 'facility' ? `vms/v1/facility/visit/${item.visit_data._id}/` : `vms/v1/host/visit/${item.visit_data._id}/`
        const postData = currentUser.role === 'facility'? 
            {"meta":{        
                "admin_approval": flag
            }}:{
            "meta":{        
                "host_approval": flag
            }}
        try {
            const { data } = await approveReject(updateURL, postData)
            if (data) {
                getVisitorInfo();
                Swal.fire(flag ? 'Approved' : 'Rejected', `User has been ${flag ? 'approved' : 'rejected'} succefully!`, "success");
            }
        } catch (error) {
            console.error(error)
            Swal.fire(flag ? 'Approved' : 'Rejected', `Failed to ${flag ? 'approved' : 'rejected'} user!`, "error");
        }
    }
    const getVisitorInfo = () =>{
        if (currentUser.role === 'facility') {
            getVisitorDataByLocationInfo(currentUser.location_id ? currentUser.location_id[0] : null)
        }
        else if( currentUser.role === 'user'){
            getVisitorInfoByHost()
        }
        else {
            getVisitorDatas(currentUser.customer_id);
        }
    }
    useEffect(() => {
        getSetupData(currentUser.customer_id)
        getVisitorInfo();
    }, [])
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <h2 className='fw-bolder my-2'>
                        Visitor Management
                    </h2>
                    <p>Modules - Visitor Management</p>
                    <div className='d-flex overflow-auto h-55px'>
                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                            <li className='nav-item'>
                                <a onClick={() => setActiveView('setup')}
                                    className={
                                        `nav-link text-active-primary me-6 c-p ` +
                                        (activeView === 'setup' && 'active')
                                    }
                                >
                                    Setup
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a onClick={() => setActiveView('data')}
                                    className={
                                        `nav-link text-active-primary me-6 c-p ` +
                                        (activeView === 'data' && 'active')
                                    }
                                >
                                    Data
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-sm-12">
                    {activeView === 'setup' && <div className="card">
                        <div className="card-body">
                            <FormikProvider value={formik} >
                                <form className='form' onSubmit={formik.handleSubmit} noValidate>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label className='form-label required'>Duration</label>
                                            <Field
                                                as='textarea' {...formik.getFieldProps('duration')}
                                                name='duration'
                                                className='form-control form-control-lg form-control-solid'
                                                rows={3}
                                            ></Field>
                                        </div>
                                        <div className='col-6'>
                                            <label className='form-label required'>Identification</label>
                                            <Field
                                                as='textarea' {...formik.getFieldProps('identification_type')}
                                                name='identification_type'
                                                className='form-control form-control-lg form-control-solid'
                                                rows={3}
                                            ></Field>
                                        </div>
                                    </div>
                                    <div className='fv-row mb-10 mt-6'>
                                        <div>
                                            <button type='submit' disabled={formik.isSubmitting || !formik.isValid || !formik.touched} className='btn btn-lg btn-primary me-3'>
                                                <span className='indicator-label'>
                                                    Save
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </FormikProvider>
                        </div>
                    </div>}
                    {activeView === 'data' && <div className="card">
                        <div className="card-header border-0 pt-6">
                            <div className="card-title">
                                <div className="d-flex align-items-center position-relative my-1"><i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6"><span className="path1"></span><span className="path2"></span></i>
                                    <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                </div>
                            </div>
                            <div className="card-toolbar">
                                <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                    <button type="button" className="btn btn-light-primary me-3" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"><i className="ki-duotone ki-filter fs-2"><span className="path1"></span><span className="path2"></span></i>Filter</button>
                                    <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
                                        <div className="px-7 py-5">
                                            <div className="fs-5 text-dark fw-bolder">Filter Options</div>
                                        </div>
                                        <div className="separator border-gray-200">
                                        </div>
                                        <div className="px-7 py-5" data-kt-user-table-filter="form">
                                            <div className="mb-10">
                                                <label className="form-label fs-6 fw-bold">Role:</label>
                                                <select className="form-select form-select-solid fw-bolder" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-kt-user-table-filter="role" data-hide-search="true"><option value="">
                                                </option>
                                                    <option value="Administrator">Administrator</option>
                                                    <option value="Analyst">Analyst</option>
                                                    <option value="Developer">Developer</option>
                                                    <option value="Support">Support</option>
                                                    <option value="Trial">Trial</option>
                                                </select>
                                            </div>
                                            <div className="mb-10">
                                                <label className="form-label fs-6 fw-bold">Last login:</label>
                                                <select className="form-select form-select-solid fw-bolder" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-kt-user-table-filter="two-step" data-hide-search="true">
                                                    <option value=""></option>
                                                    <option value="Yesterday">Yesterday</option>
                                                    <option value="20 mins ago">20 mins ago</option>
                                                    <option value="5 hours ago">5 hours ago</option>
                                                    <option value="2 days ago">2 days ago</option>
                                                </select>
                                            </div>\
                                            <div className="d-flex justify-content-end">
                                                <button type="button" className="btn btn-light btn-active-light-primary fw-bold me-2 px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="reset">Reset</button>
                                                <button type="button" className="btn btn-primary fw-bold px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="filter">Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className='table-responsive'>
                                <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                    <thead>
                                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                            <th>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        data-kt-check={isAllSelected}
                                                        data-kt-check-target='#kt_table_users .form-check-input'
                                                        checked={isAllSelected}
                                                        onChange={onSelectAll}
                                                    />
                                                </div>
                                            </th>
                                            <th>Visiter Id</th>
                                            <th className='min-w-150px'>Username</th>
                                            <th className='min-w-150px'>Mail Id</th>
                                            <th className='min-w-150px'>Host name</th>
                                            <th className='min-w-150px'>Visiting date</th>
                                            <th className='min-w-90px'>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-gray-600 fw-bold'>
                                        {visitorDataList.map((item: any, i) => {
                                            return (
                                                <tr role='row' key={i}>
                                                    <td><div className='form-check form-check-custom form-check-solid'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            data-kt-check={isSelected}
                                                            data-kt-check-target='#kt_table_users .form-check-input'
                                                            checked={isSelected}
                                                            onChange={() => onSelect(10)}
                                                        />
                                                    </div></td>
                                                    <td>{item.visit_data.visitor_id}</td>
                                                    <td>{item.visitor_profile.full_name}</td>
                                                    <td>{item.visitor_profile.email}</td>
                                                    <td>{item.visit_data.host_id}</td>
                                                    <td>{item.visit_data.visiting_date_time ? new Date(item.visit_data.visiting_date_time).toLocaleDateString() : ''}</td>
                                                    <td>
                                                        <div data-bs-toggle="dropdown" aria-expanded="false" className={`badge fw-bolder ${item.visit_data.status === 'pending' ? 'dropdown-toggle badge-light-warning' : ''} ${item.visit_data.status === 'approved' ? 'dropdown-toggle badge-light-success' : ''} ${item.visit_data.status === 'rejected' ? 'badge-light-danger' : ''}`}>
                                                            {item.visit_data.status === 'pending' ? 'Pending' : (item.visit_data.status === 'approved' ? 'Approved' : item.visit_data.status)}
                                                        </div>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" onClick={() => approveRejectUser(item, true)}>Approve</a></li>
                                                            {<li><a className="dropdown-item" onClick={() => approveRejectUser(item, false)}>Reject</a></li>}
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <a
                                                            className='btn btn-light btn-active-light-primary btn-sm c-p dropdown-toggle'
                                                            data-kt-menu-trigger='click'
                                                            data-kt-menu-placement='bottom-end'
                                                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                                                        >
                                                            Actions
                                                            {/* <KTIcon iconName='down' className='fs-5 m-0' /> */}
                                                        </a>
                                                        {/* begin::Menu */}
                                                        <div
                                                            className='dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                                                            data-kt-menu='true' aria-labelledby="dropdownMenuButton1"
                                                        >
                                                            {/* begin::Menu item */}
                                                            <div className='menu-item px-3 dropdown-item'>
                                                                <a className='menu-link px-3' data-bs-toggle="modal" data-bs-target="#visitorInfoModal" onClick={() => {
                                                                    setModalData(true)
                                                                    setSelectedItem(item)
                                                                 }}>
                                                                    View
                                                                </a>
                                                            </div>
                                                            <div className='menu-item px-3 dropdown-item'>
                                                                <a className='menu-link px-3' onClick={() => { }}>
                                                                    Edit
                                                                </a>
                                                            </div>
                                                            {/* end::Menu item */}

                                                            {/* begin::Menu item */}
                                                            <div className='menu-item px-3 dropdown-item'>
                                                                <a
                                                                    className='menu-link px-3'
                                                                    data-kt-users-table-filter='delete_row'
                                                                    onClick={async () => console.log('delete')}
                                                                >
                                                                    Delete
                                                                </a>
                                                            </div>
                                                            {/* end::Menu item */}
                                                        </div>
                                                        {/* end::Menu */}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <UsersListPagination />
                        </div>
                    </div>}
                </div>
            </div>
            <div className="modal fade" id="visitorInfoModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">View</h5>
                            <button type="button" className="btn-close" onClick={(e)=>{
                                setSelectedItem(null)
                                setModalData(false)
                            }} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {viewModal&&<VisitorInfo visitorInfo={selectedItem} />}                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={(e)=>{
                                setModalData(false)
                                setSelectedItem(null)
                                }} data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
