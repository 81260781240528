import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const GET_LOCATION_BY_CUSTOMER_URL = `${API_URL}/locations/customer/`;
const addLocationURL = `${API_URL}/locations/create/`;
const updateLocationURL = `${API_URL}/locations/update/`;
const deleteLocationURL = `${API_URL}/locations/delete/`;
const getBuildingsURL = `${API_URL}/buildings/`;
const addBuildingURL = `${API_URL}/buildings/create/`;
const updateBuildingURL = `${API_URL}/buildings/update/`;
const deleteBuildingURL = `${API_URL}/buildings/delete/`;

const addFloorURL = `${API_URL}/floors/create/`;
const updateFloorURL = `${API_URL}/floors/update/`;
const regiterURL = `${API_URL}/vms/v1/`;
// location methods
export function addLocation(data: any) {
  return axios.post<any>(addLocationURL, data);
}
export function updateLocation(data: any, id: number) {
  delete data.address;
  return axios.put<any>(`${updateLocationURL}${id}/`, data);
}
export function deleteLocation(id: number) {
  return axios.delete<any>(`${deleteLocationURL}${id}/`);
}
export function getModuleByCustomer(id: number) {
  return axios.get<any>(`${API_URL}${"/modules/customer/"}${id}/`);
}
export function getLocationByCustomer(id: number) {
  return axios.get<any>(`${GET_LOCATION_BY_CUSTOMER_URL}${id}/`);
}
// building methods
export function addBuilding(data: any) {
  return axios.post<any>(addBuildingURL, data);
}
export function updateBuilding(data: any, id: number) {
  delete data.address;
  return axios.put<any>(`${updateBuildingURL}${id}/`, data);
}
export function deleteBuilding(id: number) {
  return axios.delete<any>(`${deleteBuildingURL}${id}/`);
}
export function getBuildings() {
  return axios.get<any>(getBuildingsURL);
}

// building methods
export function addFloor(data: any) {
  return axios.post<any>(addFloorURL, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function updateFloor(data: any, id: number) {
  return axios.put<any>(`${updateFloorURL}${id}/`, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function addPublicUser(data: any) {
  return axios.post<any>(`${regiterURL}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
